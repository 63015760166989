import React from "react";
import Container from '@mui/material/Container';
import { Timeline, Events, UrlButton, ImageEvent, TextEvent, themes, createTheme } from '@merc/react-timeline';

function Roadmap() {

  document.title = "Atypical Aliens | Roadmap"
  
    const customTheme = createTheme(themes.default, {
      card: {
        backgroundColor: '#fff',
        borderRadius: "4px",
        padding: "10px"
      },
      date: {
        backgroundColor: 'rebeccapurple',
      },
      marker: {
        borderColor: 'rebeccapurple',
      },
      timelineTrack: {
        backgroundColor: 'rebeccapurple',
      }
    });

    return (
      <Container maxWidth="md">

        <Timeline theme={customTheme} opts={{ layout: "inline-evts" }}>
          <Events>
            
            <ImageEvent
              date="Pre-mint" 
              text="**Social Media Presence** <br/> Market project across social media to increase exposure. This will be done via giveaways, pre-sale whitelist and engagement. <br/><br/> While promoting the project, the tech for automating the generated characters and assets on mint will be finalized."
              src="/img/phase01.png"
              alt="Phase 1"
            >
              <div>
                <UrlButton href="https://discord.gg/SHADKNEbnH">
                  Discord
                </UrlButton>
                <UrlButton href="https://twitter.com/AtypicalAliens">
                  Twitter
                </UrlButton>
                <UrlButton href="https://www.instagram.com/atypicalaliens">
                  Instagram
                </UrlButton>
              </div>
            </ImageEvent>

            <ImageEvent 
              date="Launch!" 
              text="**Atypical Aliens has invaded!** <br/> While supporting the launch, development for an interactive/customizable Web-based NFT Viewer will begin which will allow you to view, play with and create additional assets for your Atypical Alien!" 
              src="/img/phase02.png"
              alt="Phase 2"
            />

            <ImageEvent
              date="Post-Mint"
              text="** Interactivity! ** <br/> While leveraging experience and community direction, development for the Metaverse, Games, Merch and Scavenger Hunt will begin!"
              src="/img/phase03.png"
              alt="Phase 3"
            />

            <TextEvent
              date="Future"
              text="** Community ** <br/> Future roadmap decisions will be voted by Atypical Alien NFT holders."
            />
          </Events>
        </Timeline>
      </Container>
    );
}

export default Roadmap;