import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function Aliens() {
  
  document.title = "Atypical Aliens | Aliens"
 
  return (
    <div className="collectionContainer">
      <Box sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}>       
        <Typography variant="h3" component="span" color="secondary" align="center" gutterBottom>
          Waiting For Atypical Aliens to arrive!
        </Typography>
        <Typography variant="caption" component="span" color="secondary" align="center" gutterBottom>
              Once the project this is live, this page will contain the entire collection with options to filter and see individual details.
        </Typography>
      </Box>
    </div>
  );
};