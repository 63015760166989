import React from "react";
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  document.title = "Atypical Aliens | FAQs"

  return (
    <div className="faqContainer">
        <Typography variant="h3" component="span" color="secondary" align="center" gutterBottom>
          Frequently Asked Questions
        </Typography>

      <div className="faqQuestions">

        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ flexShrink: 0 }}>
              What are Atypical Aliens?
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
            Atypical Aliens are 10,000 uniquely generated 3D digital collectables (ERC-721 standard).
          </Typography>
          <Typography paragraph>
            Focusing on innovating the NFTs/Metaverse space and bringing awareness to neurodiversity.
          </Typography>
          <Typography paragraph>
            An Atypical Alien is a membership access to BestEarth and its members-only benefits!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography sx={{ flexShrink: 0 }}>How many Atypical Aliens are there?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
              There are 10,000 Atypical Aliens available for minting!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography sx={{ flexShrink: 0 }}>
              Why NFTs?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
              Being hyper-focused on tech, computers and games for 20+ years made NFTs and the larger digital/metaverse space a natural fit. 
            </Typography>
            <Typography paragraph>
              The project's focus will be to bring this experience to the forefront and change the standards of what a NFT project should be and its offerings.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6bh-content"
            id="panel6bh-header"
          >
            <Typography sx={{ flexShrink: 0 }}>Can I resell my Atypical Alien?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
              Yes, Atypical Aliens can be re-sold on NFT Marketplaces
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7bh-content"
            id="panel7bh-header"
          >
            <Typography sx={{ flexShrink: 0 }}>How do I mint an Atypical Alien?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
              To purchase an Atypical Alien, you will need to connect your digital wallet to the website in order to 'mint' an NFT.
            </Typography>
            <br />
            <Typography paragraph>
              Once you have your wallet connected, you will need Ethereum in your wallet and when you select 'Mint' it will show the cost plus <a href="https://www.investopedia.com/terms/g/gas-ethereum.asp">gas fees.</a>
            </Typography>
            <br />
            <Typography paragraph>
              Need a wallet?
              Check out <a href="https://metamask.io/">Metamask</a>, <a href="https://www.coinbase.com/wallet">Coinbase</a> and many others!
            </Typography>
            <br />
            <Typography paragraph>
              Have more questions? Check out the <a href="https://discord.gg/SHADKNEbnH">Atypical Aliens Discord community for help!</a>
            </Typography>
            {/* </Typography> */}
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8bh-content"
            id="panel8bh-header"
          >
            <Typography sx={{ flexShrink: 0 }}>How much is it to mint Atypical Aliens?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
              Prices are still being determined!      
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel9'} onChange={handleChange('panel9')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel9bh-content"
            id="panel9bh-header"
          >
            <Typography sx={{ flexShrink: 0 }}>Donations for Mental Health Awareness?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
              This project will be donating a portion of proceeds to charities that bring awareness to mental health!
              Organizations that we support will be determined with the community!
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'panel10'} onChange={handleChange('panel10')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10bh-content"
            id="panel10bh-header"
          >
            <Typography sx={{ flexShrink: 0 }}>What is Neurodiversity?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography paragraph>
             "Neurodiversity describes the idea that people experience and interact with the world around them in many different ways; 
              there is no one "right" way of thinking, learning, and behaving, and differences are not viewed as deficits.
            </Typography>
            <Typography paragraph>
              The word neurodiversity refers to the diversity of all people, but it is often used in the context of autism spectrum disorder (ASD), as well as other neurological or developmental conditions such as ADHD or learning disabilities. 
              The neurodiversity movement emerged during the 1990s, aiming to increase acceptance and inclusion of all people while embracing neurological differences. 
              Through online platforms, more and more autistic people were able to connect and form a self-advocacy movement. 
              At the same time, Judy Singer, an Australian sociologist, coined the term neurodiversity to promote equality and inclusion of "neurological minorities." 
              While it is primarily a social justice movement, neurodiversity research and education is increasingly important in how clinicians view and address certain disabilities and neurological conditions."
            </Typography>
            <Typography paragraph>
              Read More: <a href="https://www.health.harvard.edu/blog/what-is-neurodiversity-202111232645"> Source</a>
            </Typography>
          </AccordionDetails>
        </Accordion>

      </div>
    </div>
  );
}

export default FAQ;