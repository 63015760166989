import React from "react";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

function About() {

  document.title = "Atypical Aliens | About"
  
  return (
    <div className="aboutContainer">
      <Typography variant="h3" component="span" color="secondary" align="center" gutterBottom>
        About
      </Typography>
      <Paper sx={{p:2, m:4, bgcolor: '#1d273a', color: '#ffffff'}}>
        <Typography paragraph variant="caption">
          After being exiled, a community of aliens has set out to find a new home. 
        </Typography>
        <Typography paragraph variant="caption">
          While traveling the vast universe looking for a new planet to call home, their ship's defences were found to be tampered with and the hull has received critical damage.
        </Typography>
        <Typography paragraph variant="caption">
          While escape pods are available, the closest planet is unknown. The timing of escape pod's launch is crucial to provide the correct trajectory for a safe landing on the planet nearby.
        </Typography>
        <Typography paragraph variant="caption">
          Upon landing, exploration of the environment will be crucial for survival. This story will unfold over time and the website will update graphically to reflect this.
        </Typography>
      </Paper>
      
      <Grid container spacing={4} columns={{ xs: 2, sm: 4, md: 8 }} sx={{p:8}}>
        <Grid item xs={2} sx={{maxWidth: 300}}>
            <Box>
            <Card>
              <CardMedia
                component="img"
                height="256"
                width="256"
                image="/img/alien00_512.png"
                alt="atypical alien"
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  10,000 Atypical Aliens NFTs
                </Typography>
                <Typography variant="body2">
                  Atypical Aliens are 10,000 uniquely generated 3D digital non-fungible token (NFTs) collectables. 
                <br/>
                <br/>
                  They are uniquely generated from hundreds of 3D assets and come in a variety of colours with the purpose of being used as characters for a larger universe.
                <br/>
                <br/>
                  How they interact with the world will be <a href="https://discord.gg/SHADKNEbnH"> decided with the community</a>.   
                </Typography>
              </CardContent>
            </Card>
            </Box>
          </Grid>
          
          <Grid item xs={2}>
            <Box>
            <Card>
              <CardMedia
                component="img"
                height="256"
                width="256"
                image="/img/aa_wirefame.png"
                alt="atypical alien"
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  What do I get with each Atypical Alien?
                </Typography>
                <Typography variant="caption">
                  • Profile Picture (PFP) / Avatar (*.png, *.jpg)
                  <br />
                  • Optimized Metaverse-ready 3D Model (*.glb)
                  <br />
                  • Renders (*.png, *.jpg)
                  <br />
                  • Videos (*.mp4)
                  <br />
                  • Membership Access to Members-Only benefits
                  <br />
                  • Metaverse
                  <br />
                  • Games
                  <br />
                  • Scavenger Hunts
                  <br />
                  This is an ongoing list, for the latest updates visit the <a href="https://discord.gg/SHADKNEbnH">Discord channel!</a>
                </Typography>
              </CardContent>
            </Card>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box>
            <Card>
              <CardMedia
                component="img"
                height="256"
                width="256"
                image="/img/aa_space.png"
                alt="atypical alien"
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Expanded Universe
                </Typography>
                <Typography variant="body2">
                With professional experience in delivering real-time interactive experiences, the goal is to bring the most utility to these 3D characters in the form of:
                </Typography>
                <br/>
                <Typography variant="caption">
                  • Augmented Reality (AR)
                  <br />
                  • Virtual Reality (VR)
                  <br />
                  • Metaverse-ready models
                  <br />
                  • Output Aliens in various file formats
                  <br />
                  • Integration with the website
                  <br />
                  • Customizable NFT Viewer Tool
                  <br />
                  • Mini-games with leaderboards
                  <br />
                  • Hidden Puzzle Games / Scavenger Hunts
                  <br />
                  • Integration with the newest tech!
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href="https://discord.gg/SHADKNEbnH">Suggest more in the Discord community!</Button>
              </CardActions>
            </Card>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box>
            <Card>
              <CardMedia
                component="img"
                height="256"
                width="256"
                image="/img/aa_wireframeComparison.png"
                alt="atypical alien"
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Increasing NFT Standards
                </Typography>
                <Typography variant="body2">
                  This Project is going to focus on realistic tech integration. 
                  <br />
                  <br />
                  Projects will promise huge worlds with lots of high-poly assets, clothes with no polycount limits and huge textures.
                  If 3d assets are not set up with real-time standards then loading into a metaverse you would quickly run into a problem with performance.
                  Rendering all the characters, clothes, accessories, environment, etc. will quickly bottleneck the server as you multiply this by the number of users.
                  <br />
                  <br />
                  Web-streaming services like pixel streaming experience issues with input delay, pixelation in video rendering and more.
                  You don't own the content and it's being rendered on other servers at all times and delivered to you.
                  <br />
                  <br />
                  Instead, the approach of this project is to deliver an amazing experience using standardized technology with easy access that is available today while still innovating for the future!
                  The Atypical Aliens were purpose-built for a lot to be rendered on the screen at the same time in order to achieve this goal!
                </Typography>
              </CardContent>
            </Card>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box>
            <Card>
              <CardMedia
                component="img"
                height="256"
                width="256"
                image="/img/aa_eyes.png"
                alt="atypical alien"
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Mental Health
                </Typography>
                <Typography variant="body2">
                  This project will be donating a portion of proceeds to charities that bring awareness to mental health!
                  <br />
                  <br />
                  Organizations that we support will be determined with the community!
                  <br />
                  <br />
                  It's important that we understand that there are people that think differently than others.
                  <br />
                  <br />
                  Society has long determined that those with a different way of thinking are somehow "ill" and should be treated/fixed.
                  <br />
                  <br />
                  Often people are misdiagnosed with depression because they are exhausted from hidden social rules or other requiring validation in their actions.
                  <br />
                  <br />
                  We need to bring awareness and de-stigmatize labels used to express how people operate.
                </Typography>
              </CardContent>
            </Card>
            </Box>
          </Grid>

          <Grid item xs={2}>
            <Box>
            <Card>
              <CardMedia
                component="img"
                height="256"
                width="256"
                image="/img/eye.jpg"
                alt="atypical alien"
              />
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Atypical Aliens Team
                </Typography>
                <Typography variant="h4">
                  Jambri
                  </Typography>
                  <br/>
                  <Typography variant="body2">
                  • Expert in real-time 3D interactive development (enterprise apps and games)
                  <br/>
                  <br/>
                  • Lurking the internet, computers and tech for over 20 years! #Introverted
                  <br/>
                  <br/>
                  • Innovative, efficient and logical thinker 
                  
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" href="https://twitter.com/jamezbriggs">Twitter</Button>
                <Button size="small" href="https://www.instagram.com/jambriest/">Instagram</Button>
              </CardActions>
            </Card>
            </Box>
          </Grid>

      </Grid>
    </div>
  );
}

export default About;