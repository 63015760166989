import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import { blue } from '@mui/material/colors';
import { orange } from '@mui/material/colors';
import './styles.css'

import {
  Navigation,
  Home,
  About,
  Aliens,
  Roadmap,
  FAQ,
  // Alien,
  ErrorPage
} from './components';

const theme = createTheme({
  palette: {
    primary: {
      main: blue[400],
      light: blue[50]
    },
    onprimary: {
      main: '#FFFFFF'
    },
    secondary: {
      main: pink[500]
    },
    action: {
      main: orange[600]
    },
    surface: {
      main: '#FFFFFF'
    }
  },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
<React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
          <Navigation />          
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/home' element={<Home />}  />

            <Route path='/aliens' element={<Aliens />} >
            </Route>
            {/* <Route path='/aliens/:id' element={<Alien />} /> */}
            <Route path='/roadmap' element={<Roadmap />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/about' element={<About />} />
            <Route path='*' element={<ErrorPage />} />
          </Routes>
      </ThemeProvider>
    </Router>
    </React.StrictMode>
);