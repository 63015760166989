import React, { useState, useEffect, useContext } from 'react';
// import Fab from '@mui/material/Fab';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import ButtonGroup from '@mui/material/ButtonGroup';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import Unity, { UnityContext } from "react-unity-webgl";
import '../styles.css';
import PropTypes from 'prop-types';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

const unityContext = new UnityContext({
    loaderUrl: "home/AA.loader.js",
    dataUrl: "home/AA.data.br",
    frameworkUrl: "home/AA.framework.js.br",
    codeUrl: "home/AA.wasm.br",
    streamingAssetsUrl: "StreamingAssets",
    companyName: "BestCompany",
    productName: "AA",
    productVersion: "1.0",
    webGLContextAttributes: {
        alpha: true,
        antialias: true,
        depth: true,
        failIfMajorPerformanceCaveat: true,
        powerPreference: "high-performance",
        premultipliedAlpha: true,
        preserveDrawingBuffer: true,
        stencil: true,
        desynchronized: true,
        xrCompatible: true,
    },
});
  
export default function Home() {
    const [progression, setProgression] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [didError, setDidError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { navigator } = useContext( NavigationContext );
    // const [totalAliens, setTotalAliens] = useState (0);
    // const [mintFee, setMintFee] = useState (0);
    // const [ownedAliens, setOwnedAliens] = useState(0);
    // const [mintQuantity, setMintQuantity] = useState(1);
    // const [maxAliens, setMaxAliens] = useState(0);

    useEffect(() => {
        async function unblockFunction() {
        const unblock = navigator.block( async ( tx ) => {
             if(isLoaded) {
                 await unityContext.quitUnityInstance()
                 await setIsLoaded(false)
                 tx.retry()
             } else {
                 unblock()
                 tx.retry()
             }
         } );
         return unblock;
        }
        unblockFunction();
    }, [navigator,isLoaded])

    const renderLoader = () => {
        if (!isLoaded) {
            return (
                <Box className='progress' sx={{ width: '40%'}}>
                    <LinearProgressWithLabel value={ progression * 100 } />
                </Box>
            )
        }
        return null;
    }

    function handleOnUnityCanvas(canvas) {
        const context = canvas.getContext("webgl");
        const contextAttributes = context?.getContextAttributes();
        console.log(contextAttributes);
        canvas.setAttribute("role", "unityCanvas");
    }

    function handleOnUnityProgress(progression) {
        setProgression(progression);
    }

    function handleErrorMessage(message) {
        setDidError(true);
        setErrorMessage(message);
    }

    function handleOnUnityLoaded() {
        setIsLoaded(true);
    }

    // function handleOnUnityRotationDidUpdate(degrees) {
    //     setCubeRotation(Math.round(degrees));
    // }

    // function handleOnUnityClickedPosition({x, y}) {
    //     setClickPosition({ x, y });
    // }

    // function handleOnUnitySayMessage(message) {
    //     setSaidMessage(message);
    // }

    // function handleOnClickUnMountUnity() {
    //     if (isLoaded === true) {
    //         setIsLoaded(false);
    //     }
    //     setIsUnityMounted(isUnityMounted === false);
    // }

    // function changeMintQuantity(count){
    //     console.log("Changing Mint Quantity to " + count);
    //     setMintQuantity(count);
    //     console.log('changeMintQuantity: ' + mintQuantity);
    // }

    useEffect(() => {
        unityContext.on("canvas", handleOnUnityCanvas);
        unityContext.on("progress", handleOnUnityProgress);
        unityContext.on("loaded", handleOnUnityLoaded);
        // unityContext.on("Say", handleOnUnitySayMessage);
        unityContext.on("error", handleErrorMessage);
        return function () {
            unityContext.removeAllEventListeners();
        };
    }, []);

    const [isUnityMounted, setIsUnityMounted] = useState(true);

    // const handleMint = async () => {
    //     console.log('mint');
    // }
    

    return ( 
        <React.Fragment>
        {isUnityMounted === true && (
            <React.Fragment>
                <div style={{ background: '#161F31', height: 'calc(100% - (64px))', width: '100%' }}>
                    
                {renderLoader()}

                    {/* <div className='mintContainer'>
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            position: 'absolute',
                            bottom: '4%',
                        }}>                        
                            <Card sx={{ 
                                p: 2, 
                                minWidth: 275,
                                backgroundColor: 'rgba(128, 128, 128, 0.2)',
                                backdropFilter: 'blur(8px)',}} 
                                raised
                            >
                                    <CardContent justify="center" sx={{ m:0, p:0, textAlign: 'center', }}>
                                        <Typography color="text.primary" gutterBottom>
                                            Escape Pods Launched:
                                        </Typography>
                                        <Typography color="text.secondary" position={'middle'} variant="h4">
                                            { totalAliens ? totalAliens + ' / ' + maxAliens : ( 'SOON' ) }
                                        </Typography>
                                        
                                    </CardContent>
                            </Card>
                        </Box>
                    </div> */}
                    <Unity className="unity-canvas" unityContext={unityContext} style={{ width: "100%", height: "100%", background: "#161F31" }}/>
                </div>
            </React.Fragment>
        )}
        </React.Fragment>
    )
}