import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function ErrorPage(){
    console.log('error!');
    return (
        <div className="errorContainer">
            <Box sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>       
                <Typography variant="h3" component="span" color="secondary" align="center" gutterBottom>
                Page Not Found!
                </Typography>
                <Typography variant="caption" component="span" color="secondary" align="center" gutterBottom>
                   You may be far from home...
                </Typography>
            </Box>
        </div>
    );
};

export default ErrorPage;