import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';
import Zoom from '@mui/material/Zoom';


const pages = ['Aliens', 'Roadmap', 'FAQ', 'About'];

const Navigation = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  
  const navPressed = param => e => {
    console.log(param);

    handleCloseNavMenu();
  }


  return (
    <AppBar position="static" style={{background: 'linear-gradient(146deg, rgba(67,0,137,1) 0%, rgba(130,255,161,1) 100%)'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

          <NavLink 
            to='/'
            style={({ isActive }) => ({
              color: isActive ? '#FB8C00' : '#42A5F5',
              textDecoration: 'none'
           })}
          >
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
              color='onprimary.main'
            >
              Atypical Aliens
            </Typography>
          </NavLink>


          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="onprimary"
            >
            <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={navPressed(page)}>
                  
                  <NavLink 
                    to={page}
                    style={({ isActive }) => ({
                      color: isActive ? '#FB8C00' : '#42A5F5',
                      textDecoration: 'none'
                   })}
                  >
                    {page}
                  </NavLink>

                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            color="common.white"
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
          >
             <a href='/' style={{textDecoration: 'none', color: '#fff'}}> 
              Atypical Aliens
             </a>
          </Typography>
          
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
          
            {pages.map((page) => (
              <MenuItem key={page} onClick={navPressed(page)} >
              
              <NavLink 
                to={page}
                style={({ isActive }) => ({
                   color: isActive ? '#FB8C00' : '#fff',
                  textDecoration: 'none',
                })}
              >
                {page}
             
              </NavLink>
              
              </MenuItem>
            ))}
          
          </Box>

          <Box sx={{ mx:4 }}>
            
            <Tooltip title="Discord" TransitionComponent={Zoom} placement="bottom">
              <IconButton href="https://discord.gg/SHADKNEbnH">
                <img color="#ffffff" src='/img/discord.svg' alt="discord" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Twitter" TransitionComponent={Zoom} placement="bottom">
              <IconButton href="https://twitter.com/AtypicalAliens">
                <TwitterIcon htmlColor="#ffffff" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Instagram" TransitionComponent={Zoom} placement="bottom">
              <IconButton href="https://www.instagram.com/atypicalaliens">
                <InstagramIcon htmlColor="#ffffff" />
              </IconButton>
            </Tooltip>

          </Box>

        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Navigation;